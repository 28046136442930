<template>
	<a-spin :spinning="loading">
		<a-select v-model:value="selectId" @change="onChange" :disabled="disabled" :placeholder="placeholder" style="min-width: 200px;">
			<a-select-option v-for="item in list" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
		</a-select>
	</a-spin>
</template>

<script>
	import {getHallList} from "@/service/modules/cinema";
	export default {
		model: {
			prop: 'value'
		},
		props: {
			cinemaId: {
				type: [Number, undefined],
				default: undefined
			},
			value: {
				type: [Number, undefined],
				default: undefined
			},
			placeholder: {
				type: String,
				default: '请选择'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			cinemaId(newVal) {
				if (newVal) {
					this.getData();
				} else {
					this.selectId = undefined;
					this.list = [];
				}
			},
			value(newVal) {
				this.selectId = newVal;
			}
		},
		data() {
			return {
				loading: false,
				selectId: undefined,
				list: [],
			}
		},
		created() {
			if (this.cinemaId) {
				this.getData();
			}
		},
		methods: {
			async getData() {
				this.loading = true;
				let ret = await getHallList({
					cinemaId: this.cinemaId
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
				}
			},
			onChange(e) {
				this.$emit('update:value', e);
			}
		}
	}
</script>

<style>
</style>