<template>
	<a-spin :spinning="loading">
		<a-select v-model:value="selectId" @change="onChange" :disabled="disabled" :placeholder="placeholder" style="min-width: 200px;">
			<a-select-option v-for="item in list" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
		</a-select>
	</a-spin>
</template>

<script>
	import {getCinemaAllList} from "@/service/modules/cinema";
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: [Number, undefined],
				default: undefined
			},
			placeholder: {
				type: String,
				default: '请选择'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			value(newVal) {
				this.selectId = newVal;
			}
		},
		data() {
			return {
				loading: false,
				selectId: undefined,
				list: [],
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData() {
				this.loading = true;
				let ret = await getCinemaAllList({})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data;
				}
			},
			onChange(e) {
				this.$emit('update:value', e);
			}
		}
	}
</script>

<style>
</style>